import React, {Fragment} from 'react'
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import Feature from '../../components/feature'
import Footer from '../../components/footer'



const AboutPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Nosotros'} pagesub={'Nosotros'}/>
            <Feature/>
            <Footer/>
        </Fragment>
    )
};
export default AboutPage;

