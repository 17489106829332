import React from "react";

import blog1 from "../../img/news-1.png";


import "./style.css";

const BlogSingle = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <section className="blog-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-left">
              <div className="row">
                <div className="col-lg-12">
                  <div className="news-img">
                    <img src={blog1} alt="img" />
                  </div>
                </div>
              </div>
              <div className="blog-left-content">
                <div className="blog-info">
                  <div className="b_info_flex">
                    
                    <div className="blog-info-date">
                      <p>
                        <i className="far fa-calendar-alt" />
                        21/Febrero/2022
                      </p>
                    </div>
                  </div>
                  
                </div>
                <h2>Cuatro razones por las que SÍ debes rentar un baño portátil para tus eventos</h2>
                <p>
                ¿Necesitas rentar baños para tu evento, pero tienes miedo de que tus invitados se sientan incómodos y no quieran usarlos?
Generalmente, el hablar sobre baños portátiles nos hace tener pensamientos negativos y de disgusto, por lo que mucha gente se rehúsa a usarlos, aunque no haya otra opción. Los baños portátiles se pueden convertir en una gran necesidad cuando se realizan diferentes tipos de eventos. La importancia de estos, radica en que pueden ser la diferencia entre que tus invitados recuerden tu evento como algo memorable o algo terrible.
Es por esto, que en WC Company te ofrecemos otra alternativa “Remolques de lujo: comodidad y clase para tus invitados” y a continuación, te presentamos cuatro razones por las que SÍ es posible rentar un baño portátil dentro de cualquier evento.
                </p>
                <p>
                Mantener la imagen del evento
Los remolques de lujo son una excelente opción para grandes eventos como bodas; quince años; graduaciones; reuniones familiares o simplemente eventos privados. Incluso para eventos masivos como conciertos o eventos deportes son muy útiles.
Cuando realizas un evento, sobre todo al aire libre, en ocasiones no hay instalaciones sanitarias; o los sanitarios no son suficientes para el numero de invitados, ni se encuentran en buenas condiciones. Con el fin de satisfacer e impresionar a los asistentes, la renta de remolques de lujo será tu mejor aliado. De está forma, los invitados estarán satisfechos con su asistencia. ¿Quién dijo que no se puede brindar el máximo confort?
                </p>
                <p>
                Facilidad de limpieza
La limpia de baños fijos durante un evento no es nada fácil, sobretodo cuando las instalaciones no son las suficientes; pues esto puede incitar a que los invitados hagan sus necesidades a los alrededores, ensuciando no solo los baños sino la locación.
Los remolques de lujo son baños de primera clase, cuales cuentan con sistema de fresh water flush, que permite mantener el baño limpio y con buen olor en todo momento. Además, tienen una bomba que de abastecimiento de agua la cual es vaciada directamente en el tanque de desperdicios. La contratación del servicio disminuye la preocupación por mantener una limpieza, teniendo las instalaciones en óptimas condiciones.
                </p>
                <p>
                Instalación sencilla
Una de las múltiples ventajas de este servicio es que los remolques pueden ser colocados en donde mejor se acomode para las necesidades del cliente o del evento. Los remolques
 
están disponibles para ser rentados por un solo día (para un evento), para una semana, mes o periodos más largos, teniendo descuentos especiales si se renta por determinado tiempo.
                </p>
                <p>
                Instalación sencilla
Una de las múltiples ventajas de este servicio es que los remolques pueden ser colocados en donde mejor se acomode para las necesidades del cliente o del evento. Los remolques
 
están disponibles para ser rentados por un solo día (para un evento), para una semana, mes o periodos más largos, teniendo descuentos especiales si se renta por determinado tiempo.
                </p>
                <p>
                Garantizar la higiene de tus invitados
Al contar los remoques con despachadores de papel higiénico y desinfectante; total privacidad; ventilación con extractor de olores; suficientes lavamanos y cubículos; por lo que el invertir en la renta de este tipo de remolques garantiza el tener baños increíbles, limpios y cómodos durante todo el evento.
                </p>
                <p>-Daniel Zones</p>
                
              </div>
              
              
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
