import React from "react";
import Header from '../../../../components/header'
import PageTitle from '../../../../components/pagetitle'
import Footer from '../../../../components/footer'
import Slider from "react-slick";
import "./style.css";

import ServiceBg1 from "../../../../img/LavamanosSanitariodeLujo1.png";
import ServiceBg2 from "../../../../img/LavamanosSanitariodeLujo2.png";
import ServiceBg3 from "../../../../img/LavamanosSanitariodeLujo3.png";
import ServiceBg4 from "../../../../img/LavamanosSanitariodeLujo4.png";
import ServiceBg5 from "../../../../img/LavamanosSanitariodeLujo5.png";
import ServiceBg6 from "../../../../img/LavamanosSanitariodeLujo6.png";

 

const SanitariodeLujo = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2200,
  }; 
  return (
    <div>
      <Header/>
      <PageTitle pageTitle={'DETALLES DE SERVICIO'} pagesub={'Detalles de servicio'}/>
      <section className="service-video-box-area" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="site-heading">
                <h3 className="sub-title">SERVICIO</h3>
                <h2 className="section-title">
                Sanitario de Lujo
                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-video-box-text">
                <p>
                Esta es la unidad de primera línea de la industria, de la más alta calidad. Sugeridos para su uso en eventos especiales o festejos.
Equipado con: mingitorio, lavamanos y depósito wc. El lavamanos y el depósito wc están equipados con sistema flush, que es un pedal para bombear el agua del lavamanos y para descargar los residuos del tanque.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-video-box">
              <Slider {...settings}>
                <img src={ServiceBg1} alt="img" />
                <img src={ServiceBg2} alt="img" />
                <img src={ServiceBg3} alt="img" />
                <img src={ServiceBg4} alt="img" />
                <img src={ServiceBg5} alt="img" />
                <img src={ServiceBg6} alt="img" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
      
    </div>
  );
};
export default SanitariodeLujo;
