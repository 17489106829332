import React from "react";
import ContactForm from "../ContactFrom";
import "./style.css";
import imagen1 from "../../img/B16.png"

const Contactpage = () => {
  return (
    <section className="contact-page-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="contact-form">
              <div className="site-heading" data-aos="fade-up">
                <h3 className="sub-title">Contáctanos</h3>
                <h2 className="section-title">Pide cotización</h2>
              </div>
              <ContactForm />
            </div>
          </div>
          <div className="col-lg-5 col-sm-6">
            <div className="contact-page-left">
              <h3>Info de contacto.</h3>
              <div className="contact-info">
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fas fa-map-marker-alt" />
                  </div>
                  <div className="contact-info-text">
                    <h5>Área de trabajo</h5>
                    <p>
                    Corredor Industrial León-Querétaro
                    <br /> 
                    Todo el estado de Guanajuato
                    <br /> 
                    Querétaro, Querétaro (y municipios aledaños)
                    <br /> 
                    Lagos de Moreno, Jalisco 

                    </p>
                  </div>
                </div>
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fas fa-phone-alt" />
                  </div>
                  <div className="contact-info-text">
                    <h5>Teléfono</h5>
                    <p>(477) 716-78-79</p>
                  </div>
                </div>
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fas fa-envelope" />
                  </div>
                  <div className="contact-info-text">
                    <h5>Correo</h5>
                    <p>ventas@wccompany.com.mx</p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-sm-6">
            <div className="contact-page-right">
            <img src={imagen1} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contactpage;
