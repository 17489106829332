import React, {Fragment} from 'react'
import Header from '../../../components/header'
import PageTitle from '../../../components/pagetitle'
import Footer from '../../../components/footer'
import Listadeeventos from '../../../components/Listadeservicios/Eventos'


const Eventos =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Eventos'} pagesub={'Eventos'}/>
            <Listadeeventos/>
            <Footer/>
        </Fragment>
    )
};
export default Eventos;
