import React, { useState } from "react";
import Logo from "../../img/logo.png";
import Logo2 from "../../img/logo2.png";
import MobileMenu from "../../components/MobileMenu";
import { Link } from "react-router-dom";

import "./style.css";

const Header = () => {
  const [show, setShow] = useState(false);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };
 
  return (
    <header className="header">
      <div className="row">
        <div className="col-lg-12">
          <div className="header-inn">
            <div className="site-logo">
              <Link to="/">
                <img src={Logo} alt="" />
              </Link>
            </div>

            <div className="site-logo1">
              <Link to="/">
                <img src={Logo2} alt="" />
              </Link>
            </div>

            <div className="header-navigation">
              <div className="mainmenu">
                <nav id="menu">
                  <ul className="dropdown">
                    <li>
                      <Link to="/">Inicio</Link>
                    </li>

                    <li>
                      <Link to="/about">Nosotros</Link>
                    </li>
                    
                    <li>
                      <Link to="/service">Servicios</Link>
              
                    </li>
                    
                    <li>
                      <Link to="/blog">
                        Blog
                      </Link>
                      
                    </li>
                    
                  </ul>
                </nav>
              </div>
              <div className="header-action">
                
                <Link to="/contact" className="header-btn">
                  <div className="icon-holder">
                    <i className="far fa-envelope"></i>
                  </div>
                  Contáctanos
                </Link>
              </div>
             
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
