import React from "react";
import ServiceBg from "../../../../img/service-bg-1.png";
import Header from '../../../../components/header'
import PageTitle from '../../../../components/pagetitle'
import Footer from '../../../../components/footer'
import Slider from "react-slick";
import ServiceBg2 from "../../../../img/REMOLQUE4.png";
import ServiceBg3 from "../../../../img/Remolque 2.png";
import ServiceBg4 from "../../../../img/Remolque Agrícola1.png";


import "./style.css";

const RemolquesAgrícolas = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2200,
  }; 
  return (
    <div>
      <Header/>
      <PageTitle pageTitle={'DETALLES DE SERVICIO'} pagesub={'Detalles de servicio'}/>
      <section className="service-video-box-area" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="site-heading">
                <h3 className="sub-title">SERVICIO</h3>
                <h2 className="section-title">
                Remolques Agrícolas
                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-video-box-text">
                <p>
                Sanitarios montados sobre un remolque que permite el movimiento dentro de los campos de cultivo. Todos nuestros remolques cuentan con certificado de inocuidad y están equipados con lo siguiente:
Dos sanitarios sencillos, 2 tarjas lavamanos de acero inoxidable, despachadores de jabón, toallero, depósito de agua limpia y de aguas grises de los lavamanos, basurero y tirones para ser enganchados con otros remolques
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-video-box">
              <Slider {...settings}>
                <img src={ServiceBg2} alt="img" />
                <img src={ServiceBg3} alt="img" />
                <img src={ServiceBg4} alt="img" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
      
    </div>
  );
};
export default RemolquesAgrícolas;
