import React from "react";
import { Link } from "react-router-dom";


import serviceIcon1 from "../../img/service-icon-1A.png";
import serviceIcon2 from "../../img/service-icon-2A.png";
import serviceIcon3 from "../../img/service-icon-3A.png";
import serviceIcon4 from "../../img/service-icon-4A.png";

import "./style.css";

const ServiceList = () => {
  return (
    <section className="service-area service-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="service-icon">
                <img src={serviceIcon1} alt="img" />
              </div>
              <div className="service-text">
                <h3>Eventos</h3>

                <Link to="/Eventos" className="cta-btn btn-border">
                Ver más
                </Link>
              </div>
             
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="250"
            >
              <div className="service-icon">
                <img src={serviceIcon2} alt="img" />
              </div>
              <div className="service-text">
                <h3>Construcción</h3>

                <Link to="/Construcion" className="cta-btn btn-border">
                Ver más
                </Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="service-icon">
                <img src={serviceIcon3} alt="img" />
              </div>
              <div className="service-text">
                <h3>Agricultura</h3>
                <Link to="/Agricultura" className="cta-btn btn-border">
                Ver más
                </Link>
              </div>
             
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="service-icon">
                <img src={serviceIcon4} alt="img" />
              </div>
              <div className="service-text">
                <h3>Servicio de limpieza</h3>

                <Link to="/Serviciosdelimpieza" className="cta-btn btn-border">
                Ver más
                </Link>
              </div>
              
            </div>
          </div>
          
          
        </div>
      </div>
    </section>
  );
};
export default ServiceList;
 