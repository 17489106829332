import React from "react";
import { Link } from "react-router-dom";

import serviceImg1 from "../../../img/service-bg-1.png"
import serviceImg2 from "../../../img/service-bg-2.jpg"
import serviceImg3 from "../../../img/service-bg-3.jpg"
import serviceIcon5 from "../../../img/Pipa.png"
import serviceIcon2 from "../../../img/service-icon-2.png"
import serviceIcon3 from "../../../img/service-icon-3.png"



import "./style.css";

const ServiceList = () => {
  return (
    <section className="service-area service-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="service-icon">
                <img src={serviceIcon5} alt="img" />
              </div>
              <div className="service-text">
                <h3>Succión de Fosas</h3>
                <p>
                Succión de fosas sépticas, drenajes, trampas de grasa y más.                </p>
                <Link to="/SuccióndeFosas" className="cta-btn btn-border">
                  Leer más  
                </Link>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ServiceList;
