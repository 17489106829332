import React from "react";
import CountUp from "react-countup";

import bg from "../../img/about-bg.png";
import img1 from "../../img/about-1.png";
import signature from "../../img/signature.png";

import "./style.css";
 
const About = () => {
  return (
    <section className="about-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="about-left" data-aos="fade-right">
              <div className="site-heading">
                <h3 className="sub-title">Sobre nosotros</h3>
                <h2 className="section-title">
                Contamos con la flotilla de limpieza más grande de la región
                </h2>
              </div>
              <p>
               
              </p>
              <p>
              Somos una empresa 100% mexicana fundada en 1991 en el centro de la república. La primera dedicada a la renta de sanitarios portátiles en la región del bajío. Nos enfocamos en la calidad del servicio que ofrecemos.
              </p>
              
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 col-md-6">
            <div className="about-counter">
              <div className="counter-box">
                <h2>
                  <span className="counter">
                    <CountUp start={0} end={10000} duration={4} />
                  </span>
                </h2>
                <p>
                  Clientes<br />
                  Satisfechos
                </p>
              </div>
              <div className="counter-box">
                <h2>
                  <span className="counter">
                    <CountUp start={0} end={500} duration={4} />
                  </span>
                  +
                </h2>
                <p>
                  Trabajos <br />
                  Completos
                </p>
              </div>
              <div className="counter-box">
                <h2>
                  <span className="counter">
                    <CountUp start={0} end={28} duration={4} />
                  </span>
                  +
                </h2>
                <p>
                  Años de<br />
                  Experiencia
                </p>
              </div>
            </div>
            <div className="about-right" data-aos="fade-left">
              
              <img className="about_img_2" src={img1} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
