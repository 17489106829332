import React from "react";
import ServiceBg from "../../../../img/Sanitario-Discapacitados.png";
import Header from '../../../../components/header'
import PageTitle from '../../../../components/pagetitle'
import Footer from '../../../../components/footer'
import Slider from "react-slick";

import "./style.css";

const Sanitarioparadiscapacitados = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2200,
  };
  return (
    <div>
      <Header/>
      <PageTitle pageTitle={'DETALLES DE SERVICIO'} pagesub={'Detalles de servicio'}/>
      <section className="service-video-box-area" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="site-heading">
                <h3 className="sub-title">SERVICIO</h3>
                <h2 className="section-title">
                Sanitario para discapacitados                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-video-box-text">
                <p>
                Diseñado específicamente para usuarios con sillas de ruedas y personas con discapacidades por su gran espacio.
Equipado con: barandales interiores para soporte de los usuarios, puerta de amplio acceso, depósito wc.

                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-video-box">
              <Slider {...settings}>
                <img src={ServiceBg} alt="img" />

                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
      
    </div>
  );
};
export default Sanitarioparadiscapacitados;
