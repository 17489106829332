import React, {Fragment} from 'react'
import Header from '../../../components/header'
import PageTitle from '../../../components/pagetitle'
import Footer from '../../../components/footer'
import Listadeeventos from '../../../components/Listadeservicios/Construccion'


const Construcion =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Construcion'} pagesub={'Construcion'}/>
            <Listadeeventos/>
            <Footer/>
        </Fragment>
    )
};
export default Construcion;
