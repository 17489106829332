import React from "react";
import ServiceBg1 from "../../../../img/Remolquede SúperLujo1.png";
import ServiceBg2 from "../../../../img/Remolquede SúperLujo2.png";
import ServiceBg3 from "../../../../img/Remolquede SúperLujo3.png";
import ServiceBg4 from "../../../../img/Remolquede SúperLujo4.png";
import ServiceBg5 from "../../../../img/Remolquede SúperLujo5.png";
import ServiceBg6 from "../../../../img/Remolquede SúperLujo6.png";
import ServiceBg7 from "../../../../img/Remolquede SúperLujo7.png";
import ServiceBg8 from "../../../../img/Remolquede SúperLujo8.png";
import ServiceBg9 from "../../../../img/Remolquede SúperLujo9.png";
import ServiceBg10 from "../../../../img/Remolquede SúperLujo10.png";
import ServiceBg11 from "../../../../img/Remolquede SúperLujo11.png";
import ServiceBg12 from "../../../../img/Remolquede SúperLujo12.png";
import ServiceBg13 from "../../../../img/Remolquede SúperLujo13.png";
import ServiceBg14 from "../../../../img/Remolquede SúperLujo14.png";
import ServiceBg15 from "../../../../img/Remolquede SúperLujo15.png";



import Header from '../../../../components/header'
import PageTitle from '../../../../components/pagetitle'
import Footer from '../../../../components/footer'
import Slider from "react-slick";

import "./style.css";

const RemolquedeSúperLujo  = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2200,
  }; 
  return (
    <div>
      <Header/>
      <PageTitle pageTitle={'DETALLES DE SERVICIO'} pagesub={'Detalles de servicio'}/>
      <section className="service-video-box-area" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="site-heading">
                <h3 className="sub-title">SERVICIO</h3>
                <h2 className="section-title">
                Remolque de Súper Lujo
                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-video-box-text">
                <p>
                La máxima expresión de lujo y calidad dentro de los sanitarios VIP.
Baños totalmente iluminados artificialmente, con calefacción y aire acondicionado, ventilación con extractor de olores, muy amplios, con acabados de lujo en madera y tocador con lavamanos integrado. Permite dar el máximo confort a tus invitados. Cuenta con secciones independientes de damas y caballeros. Equipados con: cubículos privados con wc, lavamanos, mingitorios.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-video-box">
                 <Slider {...settings}>
                <img src={ServiceBg1} alt="img" />
                <img src={ServiceBg2} alt="img" />
                <img src={ServiceBg3} alt="img" />
                <img src={ServiceBg4} alt="img" />
                <img src={ServiceBg5} alt="img" />
                <img src={ServiceBg6} alt="img" />
                <img src={ServiceBg7} alt="img" />
                <img src={ServiceBg8} alt="img" />
                <img src={ServiceBg9} alt="img" />
                <img src={ServiceBg10} alt="img" />
                <img src={ServiceBg11} alt="img" />
                <img src={ServiceBg12} alt="img" />
                <img src={ServiceBg13} alt="img" />
                <img src={ServiceBg14} alt="img" />
                <img src={ServiceBg15} alt="img" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
      
    </div>
  );
};
export default RemolquedeSúperLujo ;
