import React from "react";
import { Link } from "react-router-dom";
import serviceIcon1 from "../../img/service-icon-1A.png";
import serviceIcon2 from "../../img/service-icon-2A.png";
import serviceIcon3 from "../../img/service-icon-3A.png";
import serviceIcon4 from "../../img/service-icon-4A.png";
import "./style.css";

const ServiceSection = () => {
  return (
    <section className="service-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="servicios " > NUESTROS SERVICIOS </div>
          <div className="col-lg-5 col-md-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="service-icon">
                <img src={serviceIcon1} alt="img" />
              </div>
              <div className="service-text">
                <h3>Eventos</h3> 
                <Link to="/Eventos" className="cta-btn btn-border">
                Ver más
                </Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="250"
            >
              <div className="service-icon">
                <img src={serviceIcon2} alt="img" />
              </div>
              <div className="service-text">
                <h3>Construcción</h3>
                <Link to="/Construcion" className="cta-btn btn-border">
                Ver más
                </Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="service-icon">
                <img src={serviceIcon3} alt="img" />
              </div>
              <div className="service-text">
                <h3>Servicios de limpieza</h3>
                <Link to="/Serviciosdelimpieza" className="cta-btn btn-border">
                Ver más
                </Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="service-icon">
                <img src={serviceIcon4} alt="img" />
              </div>
              <div className="service-text">
                <h3>Agrícola</h3>
                <Link to="/Agricultura" className="cta-btn btn-border">
                Ver más
                </Link>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
