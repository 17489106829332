import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import testimonial1 from "../../img/testimonial-1.png";
import testimonial2 from "../../img/testimonial-2.png";
import testimonial3 from "../../img/testimonial-3.png";
import icon from "../../img/gridicons_quote.svg";

import "./style.css";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    var settings = {
      dots: true,
      arrows: false,
      speed: 1200,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      fade: false,
      responsive: [
        {
          breakpoint: 1030,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <section className="testimonial-area" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="site-heading">
                <h3 className="sub-title">NUESTROS TESTIMONIOS</h3>
                <h2 className="section-title">¿Qué estan diciendo?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tetimonial-slider">
                <Slider ref={(c) => (this.slider = c)} {...settings}>
                  <div className="slide">
                    <div className="single-testimonial">
                      <div className="testimonial-text">
                        <img src={icon} alt="icon" />
                        <p>
                        Los baños estaban limpios y en perfectas condiciones, fueron un éxito durante mi evento. La entrega fue puntual y el trato de las personas que me atendieron fue muy profesional. Sin duda los llamaría de nuevo si necesito baños.
                        </p>
                      </div>
                      <div className="testimonial-meta">
                        <div className="testimonial-author">
                          <h4>Miguel Ángel</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="single-testimonial">
                      <div className="testimonial-text">
                        <img src={icon} alt="icon" />
                        <p>
                        Me contactaron de inmediato cuando pedí información, tenía necesidades muy específicas para mi evento y me entendieron y ayudaron a conseguir lo que necesitaba. Conseguí todo a un precio muy razonable y con una atención increíble de todo el personal.
                        </p>
                      </div>
                      <div className="testimonial-meta">
                        <div className="testimonial-author">
                          <h4>Ximena</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="single-testimonial">
                      <div className="testimonial-text">
                        <img src={icon} alt="icon" />
                        <p>
                        Quiero tomarme un minuto para informarles que he trabajado con Wc Company durante los últimos 5 años en el ramo de la construcción y me encuentro 100% satisfecho con el servicio que hemos recibido. Debido a las condiciones actuales del mercado el precio es lo “primero” a la hora de tomar una decisión, pero estoy seguro que si terminas escogiendo a Wc Company quedarás muy contento con el servicio.
                        </p>
                      </div>
                      <div className="testimonial-meta">
                        <div className="testimonial-author">
                          <h4>Manuel</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="single-testimonial">
                      <div className="testimonial-text">
                        <img src={icon} alt="icon" />
                        <p>
                        El nivel de servicio que nos ha brindado Wc Company es de llamar la atención. Nunca nos han quedado mal, a pesar de que les hemos solicitado servicios de última hora o fuera de horario de trabajo, siempre están listos y dispuestos a ayudar. Su personal administrativo y sus operadores son muy profesionales.
                        </p>
                      </div>
                      <div className="testimonial-meta">
                        <div className="testimonial-author">
                          <h4>Ana Georgina</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Testimonial;
