import React from "react";

import Icon1 from "../../img/logo01-1.png";
import Icon2 from "../../img/logo02-1.png";
import Icon3 from "../../img/logo03-1.png";
import "./style.css";

const Feature = () => {
  return (
    <section className="about-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="site-heading text-center">
              <h3 className="sub-title">Un espacio de calidad en tiempo y forma </h3>
              <h2 className="section-title">
               Queremos ofrecerte
                <br /> la mejor opción 
              </h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-10">
            <div className="about-left">
              <div className="single-about-listing">
                <div className="about-icon">
                  <img src={Icon1} alt="icon" />
                </div>
                <div className="about-text">
                  <h3>MISIÓN</h3>
                  <p>
                  Proveer un espacio de calidad en tiempo y forma, donde las personas puedan realizar sus necesidades fisiológicas, con la renta de sanitarios portátiles.
                  </p>
                </div>
              </div>
              <div
                className="single-about-listing wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <div className="about-icon">
                  <img src={Icon2} alt="icon" />
                </div>
                <div className="about-text">
                  <h3>VISIÓN</h3>
                  <p>
                  Ser la empresa líder en renta de sanitarios portátiles, ofreciendo los servicios más eficientes e innovadores, para siempre darle al cliente el mejor servicio y experiencia.
                  </p>
                </div>
              </div>
              <div
                className="single-about-listing wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
              >
                <div className="about-icon">
                  <img src={Icon3} alt="icon" />
                </div>
                <div className="about-text">
                  <h3>VALORES</h3>
                  <p>
                  -Puntualidad: respetar los horarios y tiempos del cliente para satisfacer sus necesidades.<br/>
-Honestidad: cumplir con lo que se promete al cliente <br/>
-Cortesía: dar un servicio excepcional a los clientes.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};
export default Feature;
