import React from "react";
import { Link } from "react-router-dom";


import serviceIcon1 from "../../../img/Sanitarios Portátiles3.png"
import serviceIcon2 from "../../../img/LavamanosSanitariodeLujo7.png"
import serviceIcon3 from "../../../img/RemolqueDoble VIP8.png"
import serviceIcon4 from "../../../img/Sanitario-Discapacitados.png"
import serviceIcon5 from "../../../img/Lavamanos Sencillo.png"
import serviceIcon6 from "../../../img/Remolquede SúperLujo1.png"



import "./style.css"; 

const ServiceList = () => {
  return (
    <section className="service-area service-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="service-icon">
                <img src={serviceIcon1} alt="img" />
              </div>
              <div className="service-text">
                <h3>Sanitarios Portátiles</h3>
                <p>
                Renta y venta de sanitarios para construcción, eventos masivos y sociales. 
                </p>
                <Link to="/Sanitarioportatil" className="cta-btn btn-border">
                  Leer más  
                </Link>
              </div>
             
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="250"
            >
              <div className="service-icon">
                <img src={serviceIcon2} alt="img" />
              </div>
              <div className="service-text">
                <h3>Sanitario de Lujo</h3>
                <p>
                Esta es la unidad de primera línea de la industria, de la más alta calidad. 
                sugeridos para su uso en eventos especiales o festejos.
                </p>
                <Link to="/SanitariodeLujo" className="cta-btn btn-border">
                  Leer más 
                </Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="service-icon">
                <img src={serviceIcon3} alt="img" />
              </div>
              <div className="service-text">
                <h3>Remolques doble VIP</h3>
                <p>
                Remolques con acabados de lujo para eventos sociales y necesidades especiales. 
                </p>
                 <Link to="/RemolquesVIP" className="cta-btn btn-border">
                  Leer más  
                </Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="service-icon">
                <img src={serviceIcon4} alt="img" />
              </div>
              <div className="service-text">
                <h3>Sanitario para discapacitados </h3>
                <p>
                Diseñado específicamente para usuarios con sillas de ruedas 
                </p>
                <Link to="/Sanitarioparadiscapacitados" className="cta-btn btn-border">
                  Leer más  
                </Link>
              </div>
             
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="250"
            >
              <div className="service-icon">
                <img src={serviceIcon5} alt="img" />
              </div>
              <div className="service-text">
                <h3>Lavamanos</h3>
                <p>
                  Lavamanos ideales para exteriores, eventos masivos y uso rudo.
                </p>
                <Link to="/Lavamanos" className="cta-btn btn-border">
                  Leer más  
                </Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="service-box"
              data-aos="fade-up"
              data-aos-delay="250"
            >
              <div className="service-icon">
                <img src={serviceIcon6} alt="img" />
              </div>
              <div className="service-text">
                <h3>Remolque de Súper Lujo</h3>
                <p>
                La máxima expresión de lujo y calidad dentro de los sanitarios VIP.
                </p>
                <Link to="/RemolquedeSúperLujo" className="cta-btn btn-border">
                  Leer más  
                </Link>
              </div>
              
            </div>
          </div>
          
          
        </div>
      </div>
    </section>
  );
};
export default ServiceList;
 