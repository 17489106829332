import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

const Footer = () => {
  const NewsletterHandler = (e) => {
    e.preventDefault();
  };

  return (
    <footer className="footer-area">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 order-lg-1 order-1">
              <div className="single-footer">
                <h3>Nosotros</h3>
                <p>
                Somos una empresa 100% mexicana fundada en 1991 en el centro de la república. La primera dedicada a la renta de sanitarios portátiles en la región del bajío. Nos enfocamos en la calidad del servicio que ofrecemos.
                </p>
                <ul className="footer-contact">
                  <li>
                    <i className="fas fa-phone-square-alt" /> (477) 7167879
                  </li>
                  <li>
                    <i className="fas fa-envelope" /> ventas@wccompany.com.mx
                  </li>
                </ul>
              </div>
            </div>
            

            <div className="col-lg-2 col-sm-6 order-lg-2 order-3">
              <div className="single-footer">
                <h3>Quick Links</h3>
                <ul>
                  <li>
                    <Link to="/home">Inicio</Link>
                  </li>
                  <li>
                    <Link to="/about">Nosotros</Link>
                  </li>
                  <li>
                    <Link to="/service">Servicios</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contacto</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="fade_rule" />
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>&copy; Copyright 2021 by SapphireDev</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
