import React from "react";
import ServiceBg from "../../img/service-bg-1.png";


import "./style.css";

const ServiceSingle = () => {
  return (
    <div>
      <section className="service-video-box-area" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="site-heading">
                <h3 className="sub-title">SERVICIO</h3>
                <h2 className="section-title">
                Sanitario Sencillo
                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-video-box-text">
                <p>
                ¡El sanitario portátil más resistente del mercado! Sugeridos para el área de la construcción y áreas de uso rudo y constante.
Equipado con: mingitorio antisalpicaduras y depósito wc de 240 litros.

                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-video-box">
                <img src={ServiceBg} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>
       
    </div>
  );
};
export default ServiceSingle;
