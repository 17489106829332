import React, {Fragment} from 'react'
import Header from '../../../components/header'
import PageTitle from '../../../components/pagetitle'
import Footer from '../../../components/footer'
import Listadeeventos from '../../../components/Listadeservicios/Agricultura'


const Agricultura =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Agricola'} pagesub={'Agricola'}/>
            <Listadeeventos/>
            <Footer/>
        </Fragment>
    )
};
export default Agricultura;
