import React, {useEffect} from 'react';
import AllRoute from '../router';
import AOS from 'aos';
import 'aos/dist/aos.css'
import './App.css';
import { FaWhatsapp } from 'react-icons/fa';

const App = () => { 

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration:1000
    });
  });

  return (
    <div className="App body_wrap">
          <AllRoute/>
          <a
        href="https://wa.me/+524772632080"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp class="whatsapp-icon"/>
      </a>
    </div>
  );
}

export default App;
