import React from "react";
import ServiceBg from "../../../../img/service-bg-1.png";
import Header from '../../../../components/header'
import PageTitle from '../../../../components/pagetitle'
import Footer from '../../../../components/footer'
import Slider from "react-slick";
import "./style.css";


import ServiceBg2 from "../../../../img/RemolqueDoble VIP8.png";
import ServiceBg3 from "../../../../img/RemolqueDoble VIP3.png";
import ServiceBg4 from "../../../../img/RemolqueDoble VIP4.png";
import ServiceBg5 from "../../../../img/RemolqueDoble VIP5.png";
import ServiceBg6 from "../../../../img/RemolqueDoble VIP6.png";


const RemolquesVIP = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2200,
  }; 
  return ( 
    <div>
       <Header/>
      <PageTitle pageTitle={'DETALLES DE SERVICIO'} pagesub={'Detalles de servicio'}/>
      <section className="service-video-box-area" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="site-heading">
                <h3 className="sub-title">SERVICIO</h3>
                <h2 className="section-title">
                Remolque Doble VIP
                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-video-box-text">
                <p>
                Sanitarios de primera clase con mayor confort para invitados especiales en eventos exclusivos, con sistema de fresh water flush, que permite mantener el baño limpio y con buen olor en todo momento.
Equipado con: iluminación artificial, lavamanos con cierre automático, tocador con espejo, ventilación superior.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-video-box">
              <Slider {...settings}>
                <img src={ServiceBg4} alt="img" />
                <img src={ServiceBg2} alt="img" />
                <img src={ServiceBg3} alt="img" />
                <img src={ServiceBg5} alt="img" />
                <img src={ServiceBg6} alt="img" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
      
    </div>
  );
};
export default RemolquesVIP;
