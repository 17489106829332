import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Homepage from '../HomePage'
import AboutPage from '../AboutPage'
import ProjectPage from '../ProjectPage'
import ProjectSinglePage from '../ProjectSinglePage'
import ServicePage from '../ServicePage'
import ServiceSinglePage from '../ServiceSinglePage'
import PortfolioPage from '../PortfolioPage'
import ContactPage from '../ContactPage'
import ErrorPage from '../ErrorPage'
import BlogPage from '../BlogPage'
import BlogSinglePage from '../BlogSinglePage'
import Eventos from '../Servicios/Eventos'
import Construcion from '../Servicios/Construcion'
import Agricultura from '../Servicios/Agricultura'
import Serviciosdelimpieza from '../Servicios/Serviciosdelimpieza'
import RemolquedeSúperLujo  from '../../components/Listadeservicios/Todoslosservicios/RemolquedeSúperLujo '
import RemolquesAgrícolas from '../../components/Listadeservicios/Todoslosservicios/RemolquesAgrícolas'
import RemolquesVIP from '../../components/Listadeservicios/Todoslosservicios/RemolquesVIP'
import SanitariodeLujo from '../../components/Listadeservicios/Todoslosservicios/SanitariodeLujo'
import Sanitarioparadiscapacitados from '../../components/Listadeservicios/Todoslosservicios/Sanitarioparadiscapacitados'
import Sanitarioportatil from '../../components/Listadeservicios/Todoslosservicios/Sanitarioportatil'
import SuccióndeFosas from '../../components/Listadeservicios/Todoslosservicios/SuccióndeFosas' 
import Lavamanos from '../../components/Listadeservicios/Todoslosservicios/Lavamanos'



const AllRoute = () => { 

  return (
    <div>
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path='/home' component={Homepage} />
            <Route path='/about' component={AboutPage} />
            <Route path='/projects' component={ProjectPage} />
            <Route path='/projects-single' component={ProjectSinglePage} />
            <Route path='/service' component={ServicePage} />
            <Route path='/service-single' component={ServiceSinglePage} />
            <Route path='/portfolio' component={PortfolioPage} />
            <Route path='/contact' component={ContactPage} />
            <Route path='/404' component={ErrorPage} />
            <Route path='/blog' component={BlogPage} />
            <Route path='/blog-single' component={BlogSinglePage} />
            <Route path='/Eventos' component={Eventos} />
            <Route path='/Construcion' component={Construcion} />
            <Route path='/Agricultura' component={Agricultura} />
            <Route path='/Serviciosdelimpieza' component={Serviciosdelimpieza} />
            <Route path='/Lavamanos' component={Lavamanos} />
            <Route path='/RemolquedeSúperLujo' component={RemolquedeSúperLujo} />
            <Route path='/RemolquesAgrícolas' component={RemolquesAgrícolas} />
            <Route path='/RemolquesVIP' component={RemolquesVIP} />
            <Route path='/SanitariodeLujo' component={SanitariodeLujo} />
            <Route path='/Sanitarioparadiscapacitados' component={Sanitarioparadiscapacitados} />
            <Route path='/Sanitarioportatil' component={Sanitarioportatil} />
            <Route path='/SuccióndeFosas' component={SuccióndeFosas} />
            

          </Switch>
      </Router>
    </div>
  ); 
}

export default AllRoute;
