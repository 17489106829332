import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";

import blog1 from "../../img/news-1.png";
import blog2 from "../../img/news-2.png";
import blog3 from "../../img/news-3.png";

import "./style.css";

const BlogList = () => {
  return (
    <section className="blog-page-area"> 
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-left">
              <div className="row">
                <div className="col-lg-12">
                  <Link
                    to="/blog-single"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog1} alt="img" />
                    </div>
                    <div className="news-text">
                      <p className="news-date">Blog - 21.02.2022 </p>
                      <h3>Cuatro razones por las que SÍ debes rentar un baño portátil para tus eventos</h3>
                    </div>
                  </Link>
                </div>

              </div>
              
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default BlogList;
 